import { ISbStoryData } from 'storyblok-js-client'
import { Ref } from 'vue'
/**
 * Wrapper for Storyblok's JS client, with error handling
 */
export const useAsyncStoryblokHandler = () => {
  const runtimeConfig = useRuntimeConfig()

  /**
   * Fetches a story based on the current route
   */
  const fetchRouteStory = async (apiOptions = {}, bridgeOptions = {}) => {
    let slug = useRoute().params.slug || 'home'
    if (Array.isArray(slug)) {
      slug = slug.join('/')
    }
    // trim trailing slash
    if (slug !== '/') slug = slug.replace(/\/$/, '')
    return fetchStory(slug, apiOptions, bridgeOptions)
  }

  /**
   * Fetches a story based on the given path
   */
  const fetchStory = async (
    path: string,
    apiOptions = {},
    bridgeOptions = {}
  ) => {
    try {
      const story: Ref<ISbStoryData> = await useAsyncStoryblok(
        path,
        {
          ...apiOptions,
          version: runtimeConfig.public.STORYBLOK.VERSION,
        },
        bridgeOptions
      )
      return story
    } catch (error) {
      if (useRoute().query._storyblok) {
        return ref({
          content: {},
          name: '',
          published_at: '',
          first_published_at: '',
        })
      } else {
        throw createError({ statusCode: 404, message: 'Story not found' })
      }
    }
  }

  return {
    fetchRouteStory,
    fetchStory,
  }
}
