<script setup lang="ts">
import { useAsyncStoryblokHandler } from '~~/composables/useAsyncStoryblokHandler'

const { fetchRouteStory } = useAsyncStoryblokHandler()

const story = await fetchRouteStory({
  resolve_links: 'url',
})
</script>

<template lang="pug">
div(v-if="story")
  StoryblokComponent(
    :blok="story.content"
    :title="story.name"
    :date-updated="story.published_at"
    :date-published="story.first_published_at"
  )
</template>
